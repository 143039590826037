import styled from "styled-components";

const StyledGeneralPage = styled.main`
  a {
    color: var(--primary-color);
    text-decoration: none;
  }

  h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  p:not(.breadcrumbs) {
    margin-bottom: 1.25rem;
    /* font-size: 0.9rem; */
  }

  ul {
    margin: 0 0 1rem 2rem;
  }
`

export default StyledGeneralPage